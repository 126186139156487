/* Add this to your existing CSS file */

.chat-modal {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 100%;
    max-width: 400px;
    height: 500px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.chat-header {
    background-color: #007bff;
    color: #fff;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.chat-logo {
    display: flex;
    align-items: center;
}

.chat-logo img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    animation: bounce 1s infinite;
}

.chat-title {
    font-size: 18px;
    font-weight: bold;
}

.close-button {
    background: none;
    border: none;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
}

.chat-box {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    background-color: #f1f1f1;
    border-bottom: 1px solid #ddd;
    position: relative;
}

.chat-message {
    margin-bottom: 10px;
    clear: both;
}

.chat-message.ai span {
    background-color: #e0e0e0;
    color: #000;
    padding: 8px 12px;
    border-radius: 12px;
    display: inline-block;
    max-width: 80%;
    word-wrap: break-word;
    float: left;
}

.chat-message.user span {
    background-color: #007bff;
    color: #fff;
    padding: 8px 12px;
    border-radius: 12px;
    display: inline-block;
    max-width: 80%;
    word-wrap: break-word;
    text-align: right;
    float: right;
}

.role-options {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    right: 10px;
    bottom: 10px;
    margin: 0;
}

.role-options button {
    margin: 5px 0;
    font-size: 14px;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
}

.input-container {
    display: flex;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #ddd;
    background-color: #fff;
}

.input-container input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.input-container button {
    margin-left: 10px;
    font-size: 14px;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
}

.file-upload-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    right: 10px;
    bottom: 50px;
    padding: 10px;
    background-color: #fff;
}

.file-upload-container button {
    margin-top: 10px;
    font-size: 14px;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
}

.file-upload-input {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.file-upload-input input {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 100%;
}

.json-container {
    margin-top: 20px;
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.json-scroll-container {
    max-height: 100px;
    overflow-y: auto;
    border: 1px solid #ddd;
    padding: 5px;
    background-color: #f9f9f9;
}

@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}

.thank-you-card {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 20px;
}

.thank-you-message {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-details-container,
.file-upload-container {
    margin: 10px;
}

.file-upload-container button,
.contact-details-container button {
    margin-right: 10px;
}

.file-upload-input {
    margin: 10px 0;
}

.role-options button {
    margin: 5px;
}

.close-button {
    border: none;
    background: none;
    font-size: 24px;
    cursor: pointer;
}

.chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.chat-logo img {
    height: 30px;
    margin-right: 10px;
}

.chat-title {
    font-size: 18px;
    font-weight: bold;
}

.chat-box {
    height: 300px;
    overflow-y: auto;
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.chat-message {
    margin-bottom: 10px;
}

.chat-message.ai {
    text-align: left;
}

.chat-message.user {
    text-align: right;
}

.file-upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 600px) {
    .chat-modal {
        width: 100%;
        height: 100%;
        max-width: none;
        border-radius: 0;
    }

    .chat-header {
        padding: 5px;
    }

    .chat-title {
        font-size: 16px;
    }

    .chat-logo img {
        width: 25px;
        height: 25px;
    }

    .input-container input {
        font-size: 14px;
    }

    .input-container button {
        font-size: 14px;
        padding: 6px 10px;
    }

    .file-upload-container button {
        font-size: 14px;
        padding: 6px 10px;
    }
}
