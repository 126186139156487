.footer {
  background: linear-gradient(135deg, #d2cbd8 0%, #9ea8b8 100%); /* Gradient from purple to blue */
  padding: 20px; /* Space inside the footer */
  text-align: center; /* Center text in the footer */
  position: relative; /* Adjust position if needed */
  bottom: 0; /* Stick to the bottom of the page */
  width: 100%; /* Full width */
  color: #000; /* Change text color to white for better contrast */
}

/* Media queries for footer responsiveness */
@media (max-width: 768px) {
  .footer {
    padding: 15px; /* Reduce padding on smaller screens */
  }
}
